/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import {
  Container, Col, Row, Nav, NavItem,
} from 'reactstrap';
import ReactMapboxGl, { Marker, Feature } from 'react-mapbox-gl';

import {Helmet} from "react-helmet";

import LogoK from '../../images/logo-k.png';
import Headline from '../Headline/Headline';
import Section from '../Section/Section';

import markerIcon from '../../images/marker.png';
import styles from './Footer.module.scss';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoieWFuaWtwZWkiLCJhIjoiY2s5aDQzNzJkMDk0bzNobXd6ZWs2Zm40ciJ9.-Pd04t4-ku2bT0miTd8jYw',
  scrollZoom: false,
});

const Footer = ({ home }) => {

  useEffect(() => {
    // Note
    // const script = document.createElement('script');
    // script.src = 'https://cdn1.jameda-elements.de/widgets/siegel/81109934_1/1677644/';
    // script.id = 'jamedaScript';
    // document.body.appendChild(script);
    const script = document.createElement('script');
    script.src = 'https://cdn1.jameda-elements.de/widgets/siegel/81109934_1/1529318/';
    script.id = 'jamedaScript';
    document.body.appendChild(script);

    //Note Jameda
    // const script2 = document.createElement('script');
    // script2.src = 'https://cdn1.jameda-elements.de/widgets/siegel/81109934_1/1530294/';
    // script2.id = 'jamedaScript2';
    // document.body.appendChild(script2);

    //TOP 10
    
    const script3 = document.createElement('script');
    script3.src = 'https://cdn1.jameda-elements.de/widgets/siegel/81109934_1/1771760/';
    script3.id = 'jamedaScript3';
    document.body.appendChild(script3);


  });

  return (
    <>
      <Container style={{ overflow: 'hidden' }}>
        <Section>
         
          <Headline subtitle="Praxis für Orthopädie">Kontakt</Headline>
          <Row noGutters>
            <Col xs={12} md={6}>
              <h3 className="underlineHeadline">Anschrift und Kontakt</h3>
              <div>
                <div className={styles.item}>
                  <b>Anschrift:</b>
                  <div className={styles.itemContent}>
                    Dr.-Ruer-Platz 1
                    <br />
                    44787 Bochum
                  </div>
                </div>
                <br />
                <div className={styles.item}>
                  <b>Telefon:</b>
                  {' '}
                  <a href="tel:023497842828">0234 / 978 428 28</a>
                </div>
                <br />
                <div className={styles.item}>
                  <b>Fax:</b>
                  {' '}
                  0234 / 978 428 29
                </div>
                <br />
                <div className={styles.item}>
                  <b>E-Mail:</b>
                  {' '}
                  <a href="mailto:praxis@schubert-bochum.de">praxis@schubert-bochum.de</a>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <h3 className="underlineHeadline">Sprechstunden</h3>
              <div>
                <div className={styles.item}>
                  <b>Mo:</b>
                  {' '}
                  08:00 - 12:00
                  <br />
                  {' '}
                  14:00 - 17:00
  
                </div>
                <br />
                <div className={styles.item}>
                  <b>Di:</b>
                  {' '}
                  09:00 - 12:30
                  <br />
                  {' '}
                  14:30 - 18:00
                </div>
                <br />
                <div className={styles.item}>
                  <b>Mi:</b>
                  {' '}
                  08:00 - 12:00
                </div>
                <br />
                <div className={styles.item}>
                  <b>Do:</b>
                  {' '}
                  08:00 - 12:00
                  <br />
                  {' '}
                  14:00 - 17:00
                </div>
                <br />
                <div className={styles.item}>
                  <b>Fr:</b>
                  {' '}
                  geschlossen
                </div>
                <br />
                und nach Vereinbarung
                <br />
                <br />
                Emails werden auch Freitags gelesen.
                <br />
                Termine können immer online gebucht werden.
              </div>
            </Col>
          </Row>
        </Section>
      </Container>
      <Section dark>
        <Container>
          <Row style={{ margin: 0 }}>
            <Col>
              Sie können ihren Termin unkompliziert online vereinbaren. Natürlich können Sie uns auch telefonisch oder per E-Mail erreichen, um einen Termin aus zu machen. Nicht alle zur Verfügung stehenden Termine sind online buchbar. Bei kurzfristigem Terminwunsch bitten wir um telefonische Kontaktaufnahme.
              <br />
              <br />
              <br />
              <a
                href="https://app.arzt-direkt.de/orthopaedie-schubert/booking"
                style={{
                  margin: '0 auto', display: 'table', color: '#7B7B7A', background: '#F1F0EB', padding: '10px 20px',
                }}
              >
                Termin vereinbaren
  
              </a>
            </Col>
          </Row>
        </Container>
      </Section>
      
      <Map
        style="mapbox://styles/yanikpei/ckex60cqd1evq19pdkojjcall"
        containerStyle={{
          height: '500px',
          width: '100vw',
        }}
        center={[7.218510, 51.480090]}
        zoom={[15]}
      >
        <Marker
          coordinates={[7.218510, 51.480090]}
          anchor="bottom"
          onClick={() => {
            window.open(
              'https://www.google.com/maps/place/Dr.+B.+Schubert+Orthop%C3%A4dische+Praxis+Bochum/@51.4624152,7.218673,14z/data=!4m8!1m2!2m1!1sschubert+orthop%C3%A4die+bochum!3m4!1s0x47b8e1d3ab63df8f:0xe47e9148d2233b82!8m2!3d51.4800443!4d7.2184837',
              '_blank', // <- This is what makes it open in a new window.
            );
          }}
        >
          <img src={markerIcon} height={50} alt="Marker" />
        </Marker>
      </Map>
      <div className={styles.centerJameda}>
        {/* <div id="jameda-widget-container1677644" style={{position: 'relative', width: '272px', height: '168px', margin: '15px', backgroundImage: 'url(https://cdn1.jameda-elements.de/premium/widgets/_images/top10-top20-bg.png)', backgroundRepeat: 'no-repeat', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '12px', lineHeight: '15px'}}><div style={{position: 'absolute', top: '25px', left: '108px', width: '148px', height: '58px', overflow: 'hidden', whiteSpace: 'normal'}}><a href="https://www.jameda.de/bochum/aerzte/orthopaeden/dr-burkhard-schubert/uebersicht/81109934_1/?utm_content=Top20-01%2F2022&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" style={{fontSize: '15px', lineHeight: '18px', fontWeight: 'bold', color: '#01a3d4', textDecoration: 'none', border: '0px'}} target="_blank">Dr. med. Burkhard Schubert</a></div><div style={{position: 'absolute', top: '98px', left: '108px', width: '152px', height: '53px', overflow: 'hidden', color: '#888', fontSize: '11px', lineHeight: '13px'}}><strong>Orthopäden</strong><br />in Bochum auf&nbsp;<a href="https://www.jameda.de/bochum/aerzte/orthopaeden/fachgebiet/?utm_content=Top20-01%2F2022&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" className="jam_link_check" style={{color: '#069bd2', fontSize: '11px', textDecoration: 'none', border: '0px', backgroundColor: 'transparent !important'}} target="_blank">jameda</a></div></div> */}
        {/* <div id="jameda-widget-container1530294" style={{position: 'relative', width: '97px', height: '145px', margin: '15px 25px', backgroundImage: 'url(https://cdn1.jameda-elements.de/premium/widgets/_images/bw-small-bg.png)', backgroundRepeat: 'no-repeat', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '12px', lineHeight: '15px'}}><a href="https://www.jameda.de/bochum/aerzte/orthopaeden/fachgebiet/?utm_content=BWnote&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" className="jam_link_check" style={{display: 'block', position: 'absolute', top: '100px', left: '10px', width: '70px', height: '20px', backgroundColor: 'transparent !important'}} target="_blank" /></div> */}
        {/* <div id="jameda-widget-container1677644" style={{position: 'relative', width: '272px', height: '168px', margin: '15px', backgroundImage: 'url(https://cdn1.jameda-elements.de/premium/widgets/_images/top10-top20-bg.png)', backgroundRepeat: 'no-repeat', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '12px', lineHeight: '15px'}}><div style={{position: 'absolute', top: '25px', left: '108px', width: '148px', height: '58px', overflow: 'hidden', whiteSpace: 'normal'}}><a href="https://www.jameda.de/bochum/aerzte/orthopaeden/dr-burkhard-schubert/uebersicht/81109934_1/?utm_content=Top20-01%2F2022&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" style={{fontSize: '15px', lineHeight: '18px', fontWeight: 'bold', color: '#01a3d4', textDecoration: 'none', border: '0px'}} target="_blank">Dr. med. Burkhard Schubert</a></div><div style={{position: 'absolute', top: '98px', left: '108px', width: '152px', height: '53px', overflow: 'hidden', color: '#888', fontSize: '11px', lineHeight: '13px'}}><strong>Orthopäden</strong><br />in Bochum auf&nbsp;<a href="https://www.jameda.de/bochum/aerzte/orthopaeden/fachgebiet/?utm_content=Top20-01%2F2022&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" className="jam_link_check" style={{color: '#069bd2', fontSize: '11px', textDecoration: 'none', border: '0px', backgroundColor: 'transparent !important'}} target="_blank">jameda</a></div></div> */}
        <div id="jameda-widget-container1771760" style={{position: 'relative', width: '272px', height: '168px', margin: '15px', backgroundImage: 'url(https://cdn1.jameda-elements.de/premium/widgets/_images/top10-top20-bg.png)', backgroundRepeat: 'no-repeat', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '12px', lineHeight: '15px'}}><div style={{position: 'absolute', top: '25px', left: '108px', width: '148px', height: '58px', overflow: 'hidden', whiteSpace: 'normal'}}><a href="https://www.jameda.de/bochum/aerzte/orthopaeden/dr-burkhard-schubert/uebersicht/81109934_1/?utm_content=Top10-06%2F2022&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" style={{fontSize: '15px', lineHeight: '18px', fontWeight: 'bold', color: '#01a3d4', textDecoration: 'none', border: '0px'}} target="_blank">Dr. med. Burkhard Schubert</a></div><div style={{position: 'absolute', top: '98px', left: '108px', width: '152px', height: '53px', overflow: 'hidden', color: '#888', fontSize: '11px', lineHeight: '13px'}}><strong>Orthopäden</strong><br />in Bochum auf&nbsp;<a href="https://www.jameda.de/bochum/aerzte/orthopaeden/fachgebiet/?utm_content=Top10-06%2F2022&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" className="jam_link_check" style={{color: '#069bd2', fontSize: '11px', textDecoration: 'none', border: '0px', backgroundColor: 'transparent !important'}} target="_blank">jameda</a></div></div>
        <div id="jameda-widget-container1529318" style={{position: 'relative', width: '272px', height: '168px', margin: '15px', backgroundImage: 'url(https://cdn1.jameda-elements.de/premium/widgets/_images/top10-top20-bg.png)', backgroundRepeat: 'no-repeat', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '12px', lineHeight: '15px'}}><div id="jambw" style={{position: 'absolute', top: '11px', left: '108px', width: '152px', height: '88px', overflow: 'hidden', whiteSpace: 'normal'}}><div style={{position: 'absolute', top: '32px', left: '0px', color: '#666'}}>Bewertung wird geladen...</div></div><div style={{position: 'absolute', top: '111px', left: '108px', width: '152px', height: '40px', overflow: 'hidden', fontSize: '11px', lineHeight: '13px'}}><a href="https://www.jameda.de/bochum/aerzte/orthopaeden/fachgebiet/?utm_content=BWlast&utm_source=Kunden-Homepages&utm_medium=Badges&utm_term=81109934&utm_campaign=Badges" className="jam_link_check" style={{color: '#888', textDecoration: 'none', border: '0px', backgroundColor: 'transparent !important'}} target="_blank"><strong>Orthopäden</strong><br />in Bochum</a></div></div>
      </div>
      {
        home && (
        <Section>
          <Container>
            <Row style={{ margin: 0 }}>
              <Col>Wenn Sie mehr über unser orthopädisches Leistungsspektrum erfahren wollen, haben wir einige interessante Aspekte für Sie zusammengestellt. Schauen Sie gerne in den Bereichen Diagnostik und Therapie vorbei. Dort finden Sie verständliche Erklärungen zu den Angeboten unserer Orthopädie Praxis. Zögern Sie nicht, uns bei Fragen anzusprechen.</Col>
            </Row>
          </Container>
        </Section>
        )
      }
      <div className={styles.wrapper}>
        <Container>
          <div className={styles.nav}>
            <Nav navbar className={styles.links}>
              <NavItem>
                <Link className={[styles.link].join(' ')} to="/">Start</Link>
              </NavItem>
              <NavItem>
                <Link className={[styles.link].join(' ')} to="/praxis">Praxis</Link>
              </NavItem>
              <NavItem>
                <Link className={[styles.link].join(' ')} to="/diagnostik">Diagnostik</Link>
              </NavItem>
              <NavItem>
                <Link className={[styles.link].join(' ')} to="/therapien/klassisch">Therapien</Link>
              </NavItem>
              {/* <NavItem>
                <Link className={[styles.link].join(' ')} to="/aktuelles">Aktuelles</Link>
              </NavItem> */}
              <NavItem>
                <Link className={[styles.link].join(' ')} to="/kontakt">Kontakt</Link>
              </NavItem>
            </Nav>
          </div>
          <div className={styles.relative}>
            <img src={LogoK} alt="Logo" />
            <div className={styles.copyright}>
              Praxis für Orthopädie - Dr. Burkhard Schubert -
              {' '}
              {new Date().getFullYear()}
              {' '}
              -
              {' '}
              <Link className={styles.link} to="/impressum">Impressum</Link>
              {' '}
              -
              {' '}
              <Link className={styles.link} to="/datenschutz">Datenschutz</Link>
            </div>
          </div>
  
        </Container>
      </div>
    </>
  );
}


export default Footer;
