import React from 'react';
import PropTypes from 'prop-types';

import styles from './Headline.module.scss';

const Headline = ({
  children, subtitle, light, noMargin, h1,
}) => (
  <div className={[styles.headline, light ? styles.light : null, noMargin ? styles.noMargin : null].join(' ')}>
    <h3>{subtitle}</h3>
    {h1 && <h1>{children}</h1>}
    {!h1 && <h2>{children}</h2>}
  </div>
);

Headline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  subtitle: PropTypes.string,
  light: PropTypes.bool,
  noMargin: PropTypes.bool,
  h1: PropTypes.bool,
};

Headline.defaultProps = {
  children: '',
  subtitle: '',
  light: false,
  noMargin: false,
  h1: false,
};

export default Headline;
