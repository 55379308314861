/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import './layout.css';

const Layout = ({
  children, headerWhite, modal, home,
}) => (
  <>
    {
      !modal && <Header white={headerWhite} />
    }
    <div>
      <main>{children}</main>
    </div>
    {
      !modal && <Footer home={home} />
    }
  </>
);
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerWhite: PropTypes.bool,
  modal: PropTypes.bool,
  home: PropTypes.bool,
};

Layout.defaultProps = {
  headerWhite: false,
  modal: false,
  home: false,
};

export default Layout;
