import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Navbar, NavbarBrand, Collapse, Nav, NavItem, NavbarToggler,
} from 'reactstrap';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import logo from '../../images/logo.png';
import styles from './Header.module.scss';

const Header = ({ white }) => {
  const scrolledTop = 0;
  const [scrolled, setScrolled] = useState(scrolledTop);
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (document) {
      setScrolled(document.documentElement.scrollTop);
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setScrolled(document.documentElement.scrollTop);
    window.document.addEventListener('scroll', handleScroll);

    return () => {
      window.document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const beWhite = white && scrolled < 600;

  return (
    <div>
      <Navbar className={[styles.wrapper, beWhite ? styles.white : styles.dark, scrolled >= 600 ? styles.scrolled : null].join(' ')} expand="xl">
        <NavbarBrand href="/">
            <img alt="Orthopädie Dr. B. Schubert" className={styles.logo} src={logo} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <FontAwesomeIcon className={beWhite ? styles.white : styles.dark} icon={faBars} />
        </NavbarToggler>
        <Collapse className={styles.dropdown} isOpen={isOpen} navbar>
          <div className={styles.arrow} />
          <Nav navbar className={styles.links}>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/">Start</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/praxis">Praxis</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null, (beWhite && !isOpen) ? styles.linkWhite : null].join(' ')} to="/diagnostik">Diagnostik</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null].join(' ')} to="/therapien/klassisch">Therapien</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null].join(' ')} to="/aktuelles">Aktuelles</Link>
            </NavItem>
            <NavItem>
              <Link className={[styles.link, isOpen ? styles.openLink : null].join(' ')} to="/kontakt">Kontakt</Link>
            </NavItem>
            <NavItem>
              
              <a style={{
                  background: '#98C6BC',
                  color: 'white',
                  marginLeft: 10,
                }}
                className={[styles.link].join(' ')}
                href='https://app.arzt-direkt.de/orthopaedie-schubert/booking'>
                  Termin vereinbaren
                </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

Header.propTypes = {
  white: PropTypes.bool,
};

Header.defaultProps = {
  white: false,
};

export default Header;
