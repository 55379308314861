import React from 'react';
import PropTypes from 'prop-types';

import styles from './Section.module.scss';

const Section = ({
  showK, kLeft, dark, children,
}) => (
  <div className={[
    styles.wrapper,
    showK ? styles.showK : null,
    dark ? styles.dark : null,
    kLeft ? styles.kLeft : null,
  ].join(' ')}
  >
    {children}
  </div>
);

Section.propTypes = {
  showK: PropTypes.bool,
  kLeft: PropTypes.bool,
  dark: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.PropTypes.arrayOf(PropTypes.element).isRequired,
  ]),
};

Section.defaultProps = {
  showK: false,
  kLeft: false,
  dark: false,
};

export default Section;
